<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-primary">
          <i class="fa fa-refresh"></i> Rafraîchir
        </button>
      </div>

      <div class="col-auto">
        <button @click="print()" class="btn btn-primary">
          <i class="fa fa-refresh"></i> Imprimer
        </button>
      </div>

      <div class="col-auto">
        <button
          @click="$router.push({ name: 'subscriptions-create' })"
          class="btn btn-primary"
        >
          <i class="fa fa-plus"></i> Ajouter un abonnement
        </button>
      </div>
    </div>
    <br />

    <!-- Use DataTable Component -->
    <DataTable
      :headers="headers"
      :data="subscriptions"
      :buttons="actionButtons"
      :summableColumns="['price', 'amountToPay', 'totalPaid','totalUnpaid']"
      :itemsPerPage="10"
    >
      <!-- Custom rendering for the 'Paid' column with icons -->
      <template #cell-paid="{ row }">
        <span v-if="row.paid">
          <i class="bi bi-check2-all text-success"></i>
        </span>
        <span v-else>
          <i class="bi bi-exclamation-triangle-fill text-danger"></i>
        </span>
      </template>

      <!-- Custom rendering for the 'Created At' column with date formatting -->
      <template #cell-created_at="{ row }">
        {{ formatDate(row.created_at) }}
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from '../../components/cards/DataTable.vue'
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  components: {
    DataTable
  },
  data() {
    return {
      headers: [
        { label: 'Client', field: 'fieldFullName' },
        { label: 'Pack', field: 'pricingFullName' },
        { label: 'Heures', field: 'hours' },
        { label: 'Solde', field: 'soldHoursRest' },
        { label: 'Dernière Séance', field: 'lastBookingDate' },
        { label: 'Prix', field: 'price' },
        { label: 'Total', field: 'amountToPay' },
        { label: 'Payé', field: 'paid' },
        { label: 'Montant Payé', field: 'totalPaid' },
        { label: 'Montant a Payé', field: 'totalUnpaid' },
        { label: 'Créé Le', field: 'created_at' }
      ],
      actionButtons: [
        {
          label: 'Afficher',
          class: 'btn btn-primary',
          handler: (row) => this.showSubscription(row)
        }
      ]
    };
  },
  computed: {
    ...mapGetters('subscription', { subscriptions: 'getSubscriptions' }),
    ...mapGetters('customer', { customers: 'getCustomers' })
  },
  methods: {
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY');
    },
    async refresh() {
      await this.$store.dispatch('subscription/getAll');
    },
    async print() {
      await this.$store.dispatch('subscription/printAll');
    },
    showSubscription(row) {
      this.$router.push({
        name: 'subscriptions-details',
        params: { reference: row.reference }
      });
    }
  },
  async mounted() {
    await this.$store.dispatch('customer/getAll');
    await this.$store.dispatch('subscription/getAll');
  }
};
</script>

<style scoped>
.bg-success {
  background: #78ff5d !important;
}
</style>
